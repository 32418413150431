import React, { setGlobal, createContext } from "reactn";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/error-boundary/error-boundary.component";
import initReactnPersist from "reactn-persist";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

setGlobal({
  currentUser: null,
  authCredential: null,

  mentors: [],
  totalMentors: 0,
  approvedMentors: [],
  totalApprovedMentors: 0,
  pendingMentors: [],
  totalPendingMentors: 0,
  disabledMentors: [],
  totalDisabledMentors: 0,
  designers: [],
  totalDesigners: 0,
  guests: [],
  totalGuests: 0,
  guestMetrics: {
    total_count: 0,
    total_not_verified: 0,
    total_verified: 0,
  },
  reviews: [],
  totalReviews: 0,
  languages: [],
  expertise: [],
  countries: [],
  reviews: [],
  reportedProfiles: [],
});

initReactnPersist({
  storage: localStorage,
  whitelist: ["currentUser", "authCredential"],
  key: `@adplistdashboard_${process.env.NODE_ENV}`,
});

const Context = createContext({});

ReactDOM.render(
  <Context.Provider value={{}}>
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <App />
          <ToastContainer hideProgressBar />
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  </Context.Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
