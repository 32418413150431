import { useState, useGlobal, useEffect } from "reactn";
import { Table, Row, Col } from "react-bootstrap";
import Button from "../../components/button/button.component";
import InputField from "../../components/form/input-field/input-field.component";
import ReportModal from "../../components/modal/report-modal/report-modal.component";
import { withRouter } from "react-router-dom";
import { apiBaseUrl, mentorUrl, designerUrl } from "../../api/api";
import PaginationBar from "../../components/pagination-bar/pagination-bar.component";
import PageLayout from "../../components/page-layout/page-layout.component";
import useSWR from "swr";
import LoaderPane from "../../components/loader-pane/loader-pane.component";
import ButtonDropdown, {
  MenuItem,
} from "components/button-dropdown/button-dropdown";

import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import BlockedUsers, {
  BlockedUsersSearch,
  BlockedUsersSortBy,
} from "./blocked-users/blocked-users.page";

import { blockOrUnblockUser } from "api/services/account.service";
import ConfirmDeactivateUserModal from "../../components/modal/confirm-deactivate-user-modal/confirm-deactivate-user-modal.component";

const ReportedUsersSearch = ({ query, setQuery }) => {
  return (
    <InputField
      placeholder="Search"
      classes="small mb-20"
      isSearch
      value={query}
      onChange={(ev) => {
        setQuery(ev.target.value);
      }}
    />
  );
};

const ReportedUsersSortBy = ({ sortBy, setSortBy }) => {
  return (
    <div className="d-flex mr-10">
      <ButtonDropdown
        classes="mw-200px"
        title={
          <div className="d-flex align-center text-truncate">
            <FilterIcon /> &nbsp;&nbsp;
            <span>{sortBy.label || "Sort by"}</span>
          </div>
        }>
        <MenuItem
          active={sortBy.value === "recently_reported"}
          onClick={() =>
            setSortBy({
              label: "Recently reported",
              value: "recently_reported",
            })
          }>
          Recently reported
        </MenuItem>
        <MenuItem
          active={sortBy.value === "most_reported"}
          onClick={() =>
            setSortBy({
              label: "Most reported",
              value: "most_reported",
            })
          }>
          Most reported
        </MenuItem>
        <MenuItem
          active={sortBy.value === "reported_a_while_back"}
          onClick={() =>
            setSortBy({
              label: "Reported a while back",
              value: "reported_a_while_back",
            })
          }>
          Reported a while back
        </MenuItem>
      </ButtonDropdown>
    </div>
  );
};


const ReportedUsersFilterBy = ({ filterBy, setFilterBy }) => {
  const { data, error } = useSWR(`${apiBaseUrl}user/report/reasons/`);

  return (
    <div className="d-flex mr-10">
      {!error && (
        <ButtonDropdown
          classes="mw-200px"
          title={
            <div className="d-flex align-center text-truncate">
              <FilterIcon /> &nbsp;&nbsp;
              <span>{data ? filterBy.label || "Filter by" : "..."}</span>
            </div>
          }>
          {data && (
            <MenuItem
              active={filterBy.value === ""}
              onClick={() =>
                setFilterBy({
                  label: "Filter by",
                  value: "",
                })
              }>
              All
            </MenuItem>
          )}

          {data &&
            data.map((reason) => (
              <MenuItem
                active={filterBy.value === reason.id}
                onClick={() =>
                  setFilterBy({
                    label: reason.reason,
                    value: reason.id,
                  })
                }>
                {reason.reason}
              </MenuItem>
            ))}
        </ButtonDropdown>
      )}
    </div>
  );
};

const ReportedProfiles = ({
  query = "",
  sortBy = "",
  filterBy = "",
  setTotalCount,
}) => {
  const [currentUser] = useGlobal("currentUser");
  const [showViewModal, setShowViewModal] = useState(false);
  const [currentReportedProfile, setCurrentReportedProfile] = useState(null);

  const [paginationState, setPaginationState] = useState({
    totalItems: 0,
    page: 0,
    pageSize: 10,
    remoteNext: null,
    remotePrev: null,
  });

  const [confirmDeactivateModalState, setConfirmDeactivateModalState] =
    useState({
      show: false,
      error: null,
      processing: false,
    });

  const [reactivateUserState, setReactivateUserState] = useState({
    error: null,
    processing: false,
  });

  const getUrl = () => {
    let path = `${apiBaseUrl}user/report/?offset=${
      paginationState.page * paginationState.pageSize
    }&limit=${paginationState.pageSize}`;

    if (query) path += `&q=${query}`;

    if (sortBy) path += `&sort_by=${sortBy}`;

    if (filterBy) path += `&filter_by=${filterBy}`;

    return path;
  };

  const { data, error, mutate } = useSWR(getUrl());

  useEffect(() => {
    if (data) {
      setTotalCount(data.count);
    }
  }, [data, setTotalCount]);

  const onConfirmDeactivateUser = (reason) => {
    setConfirmDeactivateModalState({
      ...confirmDeactivateModalState,
      processing: true,
      error: null,
    });

    blockOrUnblockUser(currentUser.token.actual, {
      pk: currentReportedProfile.reported_user.id,
      status: false,
      reason: reason ?? null,
    }).then((response) => {
      if (response.success) {
        setConfirmDeactivateModalState({
          ...confirmDeactivateModalState,
          processing: false,
          success: "User deactivated successfully",
        });

        const updatedInfo = currentReportedProfile;
        updatedInfo.reported_user = response.data;

        setCurrentReportedProfile(updatedInfo);

        return;
      }

      setConfirmDeactivateModalState({
        ...confirmDeactivateModalState,
        processing: false,
        error: response.message,
      });
    });
  };

  const onReactivateUser = () => {
    setReactivateUserState({
      processing: true,
      error: null,
    });

    blockOrUnblockUser(currentUser.token.actual, {
      pk: currentReportedProfile.reported_user.id,
      status: true,
    }).then((response) => {
      if (response.success) {
        setReactivateUserState({
          processing: false,
          error: null,
        });

        const updatedInfo = currentReportedProfile;
        updatedInfo.reported_user = response.data;
        setCurrentReportedProfile(updatedInfo);

        // hide and show modal to trigger update
        setShowViewModal(false);
        setShowViewModal(true);

        return;
      }

      setReactivateUserState({
        processing: false,
        error: response.message,
      });
    });
  };

  return (
    <>
      {showViewModal && currentReportedProfile && (
        <ReportModal
          show={showViewModal}
          onHide={() => setShowViewModal(false)}
          reportedProfile={currentReportedProfile}
          onDeactivateUser={() => {
            setShowViewModal(false);
            setConfirmDeactivateModalState({
              ...confirmDeactivateModalState,
              show: true,
            });
          }}
          reactivateUserState={reactivateUserState}
          onReactivateUser={() => {
            onReactivateUser();
          }}
        />
      )}

      <ConfirmDeactivateUserModal
        show={confirmDeactivateModalState.show}
        error={confirmDeactivateModalState.error}
        processing={confirmDeactivateModalState.processing}
        userToDeactivateName={
          currentReportedProfile?.reported_user.identity_type === "Mentor"
            ? currentReportedProfile?.reported_user.mentor.name
            : currentReportedProfile?.reported_user.designer.name
        }
        success={confirmDeactivateModalState.success}
        onHide={() => {
          setConfirmDeactivateModalState({
            ...confirmDeactivateModalState,
            show: false,
            success: false,
            error: null,
            processing: false,
          });
          setShowViewModal(true);
        }}
        onConfirm={onConfirmDeactivateUser}
      />

      <LoaderPane
        loading={!data && !error}
        error={error?.message}
        noRecords={data && data?.results.length === 0}
        onReload={() => {
          mutate();
        }}
      />
      {data?.results.length > 0 && (
        <Table bordered responsive className="table-white">
          <thead>
            <tr>
              <th>Reported user</th>
              <th>Reported by</th>
              <th>Date reported</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data?.results?.map((profile) => {
              const { reported_user, reported_by } = profile;
              const identity_type = reported_by.identity_type;
              const reportedBy =
                identity_type === "Mentor"
                  ? reported_by["mentor"]
                  : reported_by["designer"];

              const user_identity_type = reported_user.identity_type;
              const reportedUser =
                user_identity_type === "Mentor"
                  ? reported_user["mentor"]
                  : reported_user["designer"];
              return (
                <tr key={profile.id}>
                  <td>
                    <a
                      href={
                        user_identity_type === "Mentor"
                          ? mentorUrl(reportedUser.slug)
                          : designerUrl(reportedUser.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={reportedUser.profile_photo_url}
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {reportedUser.name}{" "}
                        </span>
                      </div>
                    </a>
                  </td>
                  <td>
                    <a
                      href={
                        identity_type === "Mentor"
                          ? mentorUrl(reportedBy.slug)
                          : designerUrl(reportedBy.slug)
                      }
                      target="_blank"
                      rel="noreferrer">
                      <div className="d-flex pointer">
                        <div className="pic-small mr-3">
                          <img
                            className="img-f-w-obj-cover"
                            src={reportedBy.profile_photo_url}
                            alt=""
                          />
                        </div>
                        <span className="text-16 text-grey font-500">
                          {reportedBy.name}{" "}
                        </span>
                      </div>
                    </a>
                  </td>
                  <td>
                    <span className="text-16 text-grey font-500">
                      {new Date(profile.date_reported).toDateString()} LT
                    </span>
                  </td>
                  <td>
                    <span
                      className="text-16 text-success font-500 pointer"
                      onClick={() => {
                        setCurrentReportedProfile(profile);
                        setShowViewModal(true);
                      }}>
                      View
                    </span>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4}>
                <PaginationBar
                  page={paginationState.page}
                  pageSize={paginationState.pageSize}
                  totalItems={data?.count}
                  onChangePage={(newPage) => {
                    setPaginationState({ ...paginationState, page: newPage });
                  }}
                  onChangeRowsPerPage={(size) => {
                    setPaginationState({
                      ...paginationState,
                      pageSize: size,
                      page: 0,
                    });
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  );
};

const Page = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [reportedUsersQuery, setReportedUsersQuery] = useState("");
  const [reportedUsersSortBy, setReportedUsersSortBy] = useState({
    label: "",
    value: "",
  });
  const [reportedUsersFilterBy, setReportedUsersFilterBy] = useState({
    label: "",
    value: "",
  });
  const [reportedUsersCount, setReportedUsersCount] = useState(0);

  const [blockedUsersQuery, setBlockedUsersQuery] = useState("");
  const [blockedUsersSortBy, setBlockedUsersSortBy] = useState({
    label: "",
    value: "",
  });

  const [blockedUsersCount, setBlockedUsersCount] = useState(0);

  return (
    <>
      <PageLayout activeLink="reported-profiles" parentMenu="users">
        <div>
          <div className="text-grey text-20 font-bold mb-20">
            Reported | Blocked Users
          </div>

          <Row>
            <Col md={4}>
              <div className="d-flex flex-wrap mb-20">
                <Button
                  classes={`pill success ${activeTab === 0} mr-2`}
                  onClick={() => {
                    setActiveTab(0);
                    // if (!canFetchActive) setCanFetchActive(true);
                  }}>
                  Reported ({reportedUsersCount})
                </Button>
                <Button
                  classes={`pill success ${activeTab === 1}`}
                  onClick={() => {
                    setActiveTab(1);
                    // if (!canFetchDisabled) setCanFetchDisabled(true);
                  }}>
                  Blocked ({blockedUsersCount})
                </Button>
              </div>
            </Col>
            <Col md={8}>
              <div className={activeTab === 0 ? "" : "hidden"}>
                <div className="d-flex flex-wrap flex-end">
                  <ReportedUsersSortBy
                    sortBy={reportedUsersSortBy}
                    setSortBy={setReportedUsersSortBy}
                  />

                  <ReportedUsersFilterBy
                    filterBy={reportedUsersFilterBy}
                    setFilterBy={setReportedUsersFilterBy}
                  />

                  <ReportedUsersSearch
                    query={reportedUsersQuery}
                    setQuery={setReportedUsersQuery}
                  />
                </div>
              </div>

              <div className={activeTab === 1 ? "" : "hidden"}>
                <div className="d-flex flex-wrap flex-end">
                  <BlockedUsersSortBy
                    sortBy={blockedUsersSortBy}
                    setSortBy={setBlockedUsersSortBy}
                  />

                  <BlockedUsersSearch
                    query={blockedUsersQuery}
                    setQuery={setBlockedUsersQuery}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <div className={activeTab === 0 ? "" : "hidden"}>
            {ReportedProfiles({
              query: reportedUsersQuery,
              sortBy: reportedUsersSortBy.value,
              filterBy: reportedUsersFilterBy.value,
              setTotalCount: setReportedUsersCount,
            })}
          </div>

          <div className={activeTab === 1 ? "" : "hidden"}>
            {BlockedUsers({
              query: blockedUsersQuery,
              sortBy: blockedUsersSortBy.value,
              setTotalCount: setBlockedUsersCount,
            })}
          </div>
        </div>
      </PageLayout>
    </>
  );
};

export default withRouter(Page);
